<template>
    <transition
      name="zoom-fade"
      mode="out-in"
    >
        <router-view />
    </transition>
</template>

<script>

    export default {
        metaInfo() {
            const title = this.$t('title-pages.main');
            return {
                title: title,
                titleTemplate: null,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Приводите клиентов и зарабатывайе вместе с крупными компаниями из любой точки мира'
                    }
                ]
            }
        },
        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>